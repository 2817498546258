<template>
  <div >
      <Swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <SwipeItem v-for="(image, index) in swipeImgs" :key="index"><img v-lazy="image" class="swipeimg" /></SwipeItem>  
      </Swipe>
      <Grid :column-num="3" square >
<!--        <GridItem icon="userIcon" text="旧版基本信息" to="/fillbaseinfopage" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
       <GridItem icon="userIcon" text="基本信息" to="/pateintinfo" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
       <GridItem icon="userIcon" text="健康管理" @click="toJkgl" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="服务套餐" to="/healthmanage/packageidx" >
          <img slot="icon" :src="userIcon" class="gridimg" />
        </GridItem>

        <GridItem icon="userIcon" text="健康管理_全" to="/jkgl_types" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="随访问卷" @click="toFollowup" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="注册问卷" @click="toRegisterCrf" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="未填问卷" to="/weixin/send-crf-list-empty" >
          <img slot="icon" :src="userIcon" class="gridimg" />
        </GridItem>
        <GridItem icon="userIcon" text="康复影像" to="/upload" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="我的医生" to="/doctors" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
<!--        <GridItem icon="userIcon" text="医生列表" to="/tochatdoctors" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--        <GridItem icon="userIcon" text="消息" to="/tochatmessages" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
        <GridItem icon="userIcon" text="健康日记" @click="toDiary" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="评估问卷" to="/crfs" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="体检指标" to="/tjzb" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="问题反馈" to="/support" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
<!--        <GridItem icon="userIcon" text="动作评估" to="/identify" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
        <GridItem icon="userIcon" text="训练器材" to="/store" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
        <GridItem icon="userIcon" text="常见问题" to="/faq" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>
       <GridItem icon="userIcon" text="会议视频" to="/mettingVideo" >
         <img slot="icon" :src="userIcon" class="gridimg" />
       </GridItem>


          <!--          <GridItem icon="userIcon" text="医生基本信息" to="/doctorinfo" >-->
<!--              <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--          </GridItem>-->
<!--        <GridItem icon="userIcon" text="健康宣教" to="/jiankang" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--        <GridItem icon="userIcon" text="门诊简介" to="/jianjie" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--        <GridItem icon="userIcon" text="在线沟通" to="/tochatdoctors" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--        <GridItem icon="userIcon" text="康复方案" @click="torehabilitations" >-->
<!--         <img slot="icon" :src="userIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--        <GridItem icon="photo-o" text="套餐"  to="/healthmanage/packageidx" >-->
<!--          <img slot="icon" :src="packageIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--       <GridItem icon="userIcon" text="报告上传" to="/upload" >-->
<!--         <img slot="icon" :src="uploadIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--       <GridItem icon="reportIcon" text="查看报告" to="/reportidx" >-->
<!--         <img slot="icon" :src="reportIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--       <GridItem icon="photo-o" text="检前问卷"  url="https://jiankang.rilintech.com:9099/#/signin" >-->
<!--         <img slot="icon" :src="zcwjIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--       <GridItem icon="photo-o" text="体检列表" to="/records" >-->
<!--          <img slot="icon" :src="sjlbIcon" class="gridimg" />-->
<!--       </GridItem>-->
       <!--
       <GridItem icon="photo-o" text="干预方案"  @click="watingforwhile" >
          <img slot="icon" :src="gyfaIcon" class="gridimg" />
       </GridItem>-->
<!--       <GridItem icon="photo-o" text="随访问卷"  @click="toqueidx" >-->
<!--          <img slot="icon" :src="sfwjIcon" class="gridimg" />-->
<!--       </GridItem>-->
<!--      <GridItem icon="msgIcon" text="咨询" @click="connectHealthDoc" >-->
<!--        <img slot="icon" :src="msgIcon" class="gridimg" />-->
<!--       </GridItem>-->
    </Grid>
    <RLFooter/>
  </div>
</template>

<script>
import {  Grid, GridItem,Swipe, SwipeItem} from 'vant';
import {setToken,removeToken} from '@/utils/auth';
//import {getBaseInfo} from '@/api/patient/baseinfo';
import {getUser} from '@/api/user/index';
export default {
  name: 'MainGrid',
  components: {
    Grid,
    GridItem,
    Swipe,
    SwipeItem
  },
  created(){ 
    var query = this.$route.query;
    if(query.code || this.getQueryString('code')){
      //this.$store.commit('updateOpenid','123456');
      this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
      setToken(query.code?query.code : this.getQueryString('code')); 
       getUser().then((res) => {
          if(res.data){
              console.info(res.data.openid);
              this.$store.commit('updateOpenid',res.data.openid);
              this.$store.commit('updatePid',res.data.pid);
              this.$store.commit('updateBaseInfo',res.data);
          }
      });
      //getBaseInfo({}).then((response) => {
      //   console.info(response);
      // });
    }else{
      removeToken();  
    }
    
    /*this.$store.commit('user',{openid:'1234567'});
    console.info(this.$store.user.openid);
    getUser().then((response) => {
          if(response.obj){
             this.$store.commit('openid',response.obj);
          }
      });*/
  },
  data(){
     return {
       msgIcon: require("@/assets/grid/msg.png"),
       reportIcon:require("@/assets/grid/report.png"),
       userIcon:require("@/assets/grid/user.png"),
       zcwjIcon:require("@/assets/grid/zcwj.png"),
       sjlbIcon:require("@/assets/grid/sjlb.png"),
       gyfaIcon:require("@/assets/grid/gyfa.png"),
       sfwjIcon:require("@/assets/grid/sfwj.png"),
       uploadIcon:require("@/assets/grid/upload.png"),
       packageIcon:require("@/assets/grid/package.png"),
       swipeImgs:[
         require("@/assets/swipe/1.png"),
         require("@/assets/swipe/2.jpg"),
         require("@/assets/swipe/3.jpg")
       ]
     }
  },
  methods:{
    connectHealthDoc(){
      this.$toast('微信菜单点击健康咨询聊天');
    },
    watingforwhile(){
      this.$toast('敬请期待');
    },
   // getQueryString (name) {
    //  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ["", ""])[1].replace(/\+/g, '%20')) || null;
   // },
    toqueidx(){
      //toqueidx
       this.$router.push({path: '/queidx/'+this.$store.state.user.pid});
    },
    toFollowup(){
      //tofollowup
       this.$router.push({path: '/followup/',query:{follow_time:"2023-11-22"}});
    },
    torehabilitations(){
      //tofollowup
       this.$router.push({path: '/rehabilitation/'+this.$store.state.user.pid});
    },
    toRegisterCrf(){
        this.$router.push({path: '/followcrf',query:{crfname:"crf-zsyy-0001",sendid:null,itemdataorderid:null,follow_time:null}});
    },
    toJkgl(){
        this.$router.push({path: '/jkgl/',query:{}});
    },
      toDiary(){
        this.$router.push({path: '/diary/',query:{}});
    },
      toCrfs(){
        this.$router.push({path: '/diary/',query:{diary:"true"}});
    },

  }
}
</script>

<style  scoped>
.gridimg{
width: 2em;
}

.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 0px;
    text-align: center;
    background-color: #39a9ed;
  }

  .swipeimg{
    width:100%;
    height:200px;
  }
</style>
